import { BaseModel, GeoPoint } from './core';
import { SchoolLevels, SchoolLevelDescriptions } from './school';

export type Job = BaseModel & {
  schoolId: string;
  schoolRootId: string;
  schoolName: string;
  schoolTitle: string;
  schoolAddress: JobSchoolAddress | null;

  countApplications: number;

  durationFrom?: Date | string;
  durationFromUnix?: number;
  durationTo?: Date | string; // only substitute job
  durationToUnix?: number; // only substitute job
  isDurationStartNow?: boolean;
  applicationEndDate?: Date | string; // Bewerbungsfrist
  applicationEndDateUnix?: number;

  isFullTimeJob: boolean; // Fensteinstellung or Stellvertretung
  function?: (typeof JobFunctions)[number];

  activityRangeInPercentage?: JobActivityRange;
  activityRangeInHours?: JobActivityRange;

  subjects?: (typeof Subjects)[number][]; // only teacher
  workdayMatrix?: WorkdayMatrix;

  schoolLevels: (typeof SchoolLevels)[number][]; // Stufe
  schoolLevelDescriptions?: (typeof SchoolLevelDescriptions)[number][]; // only Sekundarstufe II

  requirements?: JobRequirements;

  about?: string;
  about_html?: string;

  weOffer?: string;
  weOffer_html?: string;

  yourTasks?: string;
  yourTasks_html?: string;

  yourSkills?: string;
  yourSkills_html?: string;

  closeReason?: string;

  isClosed: boolean;
  isPublic: boolean;

  publishedOnUnix?: number;
  _geoloc?: GeoPoint;
};

export type JobSchoolAddress = {
  zipcode?: string;
  city?: string;
  canton?: string;
  municipality?: string;
};

export const RequiredFulltimeJobFields = [
  'function',
  'schoolLevels',
  'activityRangeInHours',
];

export const RequiredSubstituteJobFields = [
  'function',
  'schoolLevels',
  'activityRangeInHours',
];

export type JobActivityRange = { from?: number; to?: number };

export type JobRequirements = {
  baseYear: boolean; // "Basisjahr abgeschlossen",
  diplom: boolean; // "Diplom"
  student: boolean; // "in Ausbildung"
};

export type WorkdayMatrix = WorkdayMatrixItem[];
export type WorkdayMatrixItem = { am: boolean; pm: boolean };

export const JobFunctionsPedagogic = [
  'Klassenlehrperson',
  'Fachlehrperson',
  'Schulische Heilpädagogik',
  'Deutsch als Zweitsprache',
  'Begabungs- und Begabtenförderung',
  'Logopädie',
  'Psychomotorik',
  'Schulsozialarbeit',
] as const;

export const JobFunctionsLeadership = [
  'Schulleitung',
  'Leitung Bildung',
  'Rektorat',
  'Schulpräsidium',
  'Leitung Betreuung',
  'Gesamtleitung Betreuung',
  'Fachstellenleitung',
] as const;

export const JobFunctionsAlternative = [
  'Klassenassistenz',
  'Coaching',
  'Schulberatung',
] as const;

export const JobFunctions = [
  ...JobFunctionsPedagogic,
  ...JobFunctionsLeadership,
  ...JobFunctionsAlternative,
] as const;

export const JobTypes = ['Festanstellung', 'Stellvertretung'] as const;

export const Subjects = [
  'Mathematik',
  'Deutsch',
  'Englisch',
  'Natur & Technik',
  'Französisch',
  'Natur, Mensch, Gesellschaft',
  'Bildnerisches Gestalten',
  'Textiles und Technisches Gestalten',
  'Raum, Zeit, Gesellschaft',
  'Religion, Kultur, Ethik',
  'Musik',
  'Bewegung und Sport',
  'Medien und Informatik',
  'Wirtschaft, Arbeit, Haushalt',
  'Projektunterricht',
  'Italienisch',
  'Spanisch',
  'Altgriechisch',
  'Philosophie',
  'Latein',
  'Biologie',
  'Chemie',
  'Physik',
  'Informatik',
  'Geografie',
  'Geschichte',
  'Wirtschaft und Recht',
  'Kommunikation und Sozialkompetenz',
  'Andere',
] as const;
