@if (!isCurrentUser) {
  <div class="pb-4">
    <a mat-button [routerLink]="['..']" queryParamsHandling="preserve">
      <mat-icon class="material-symbols-rounded">keyboard_backspace</mat-icon>
      Alle Ergebnisse
    </a>
  </div>
}

@if (profile$ | async; as profile) {
  <div class="grid grid-flow-row gap-24 max-w-[1420px]">
    <app-profile-header
      [contact]="contact"
      [isOwner]="isCurrentUser"
      [profile]="profile"></app-profile-header>
    @if (isCurrentUser) {
      <app-profile-progress-card
        class="conditional-hide"
        [profile]="profile"></app-profile-progress-card>
    }
    <app-profile-preferences-card
      class="conditional-hide"
      [profile]="profile"
      [isOwner]="isCurrentUser"></app-profile-preferences-card>
    <app-profile-about-me-card
      class="conditional-hide"
      [profile]="profile"
      [isOwner]="isCurrentUser"></app-profile-about-me-card>
    <app-profile-qualification-card
      id="qualifications"
      class="conditional-hide"
      [profile]="profile"
      [isOwner]="isCurrentUser"></app-profile-qualification-card>
    <app-profile-work-experiencs-card
      class="conditional-hide"
      [profile]="profile"
      [isOwner]="isCurrentUser"></app-profile-work-experiencs-card>
    <app-profile-education-card
      class="conditional-hide"
      [profile]="profile"
      [isOwner]="isCurrentUser"></app-profile-education-card>
    <app-profile-language-card
      class="conditional-hide"
      [profile]="profile"
      [isOwner]="isCurrentUser"></app-profile-language-card>
  </div>
}
