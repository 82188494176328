import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GeocodeResult, PlaceType2 } from '@googlemaps/google-maps-services-js';
import {
  ReplaySubject,
  Observable,
  switchMap,
  from,
  map,
  startWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  EMPTY,
  tap,
  skip,
} from 'rxjs';
import { LocationsService } from '../../services/locations.service';
import { SchoolLevels, SchoolLevelDescriptions } from '../../types/school';
import { SearchResponse, Hit } from '@algolia/client-search';
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../types/profile';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from '../../services/school.service';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss'],
})
export class ProfilesComponent implements OnInit {
  showFilters = false;
  page = 0;
  maxPage = 0;

  readonly defaultHitsPerPage = 32;
  readonly possibleRadius = [20, 30, 50, 100];

  fetch$ = new ReplaySubject(1);
  profiles: Hit<Profile>[] = [];
  profiles$: Observable<SearchResponse<Profile>> = EMPTY;
  locations$: Observable<GeocodeResult[]> = EMPTY;

  selectedLocation?: GeocodeResult;
  place?: any;

  currentUserId?: string;
  searchControl = new FormControl<string>('');
  locationControl = new FormControl<string>('');
  radiusControl = new FormControl<number | undefined>(undefined);
  availabilityControl = new FormControl<[]>([]);

  levelsControl = new FormControl<(typeof SchoolLevels)[number][]>([]);
  levelDescriptionsControl = new FormControl<
    (typeof SchoolLevelDescriptions)[number][]
  >([]);

  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;

  constructor(
    private locationsService: LocationsService,
    private profileService: ProfileService,
    private schoolService: SchoolService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    profileService.getMe(true).subscribe(me => {
      this.currentUserId = me.id;
      this.profiles$ = this.fetch$.pipe(
        switchMap(() =>
          from(
            profileService.search(this.searchControl.value || '', {
              facetFilters: this.getFilters(),
              aroundRadius: this.getRadius(),
              aroundLatLng: this.getLatLng(),
              page: this.page,
              hitsPerPage: this.defaultHitsPerPage,
            })
          )
        )
      );

      this.profiles$.subscribe(result => {
        this.maxPage = result.nbPages - 1;
        this.profiles = result.hits;
      });
    });

    this.locations$ = this.locationControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      filter(query => !!query && query.length > 2),
      switchMap(value => this.locationsService.searchAddress(value!)),
      filter(r => r.status === 'OK'),
      map(result => result.results)
    );

    this.availabilityControl.valueChanges
      .pipe(
        startWith(''),
        skip(1),
        debounceTime(400),
        distinctUntilChanged(),
        tap(value =>
          this.updateQueryParams('availability', JSON.stringify(value))
        )
      )
      .subscribe(() => this.reload());

    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        skip(1),
        debounceTime(300),
        distinctUntilChanged(),
        tap(value => this.updateQueryParams('query', value!))
      )
      .subscribe(() => this.reload());

    this.radiusControl.valueChanges
      .pipe(tap(value => this.updateQueryParams('radius', value!)))
      .subscribe(() => this.reload());

    this.levelsControl.valueChanges
      .pipe(
        tap(value => this.updateQueryParams('levels', JSON.stringify(value)))
      )
      .subscribe(() => this.reload());

    this.levelDescriptionsControl.valueChanges
      .pipe(
        tap(value =>
          this.updateQueryParams('levelDescriptions', JSON.stringify(value))
        )
      )
      .subscribe(() => this.reload());
  }

  async ngOnInit() {
    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
    if (queryParamMap.get('location')) {
      this.selectedLocation = JSON.parse(
        queryParamMap.get('location')!
      ) as GeocodeResult;
      this.locationControl.setValue(queryParamMap.get('location'));
    }

    if (queryParamMap.get('query')) {
      this.searchControl.setValue(queryParamMap.get('query'));
    }

    if (queryParamMap.get('radius')) {
      this.radiusControl.setValue(parseInt(queryParamMap.get('radius')!));
    }

    if (queryParamMap.get('levels')) {
      this.levelsControl.setValue(JSON.parse(queryParamMap.get('levels')!));
    }

    if (queryParamMap.get('availability')) {
      this.availabilityControl.setValue(
        JSON.parse(queryParamMap.get('availability')!)
      );
    }

    if (queryParamMap.get('levelDescriptions')) {
      this.levelDescriptionsControl.setValue(
        JSON.parse(queryParamMap.get('levelDescriptions')!)
      );
    }

    this.fetch$.next(1);

    const page = parseInt(queryParamMap.get('page')!) || 0;
    while (this.page !== page) {
      await this.onScroll();
    }

    if (queryParamMap.get('s')) {
      const s = queryParamMap.get('s');
      setTimeout(() => {
        const rect = document!.querySelector(`#s${s}`)?.getBoundingClientRect();
        const offset = window.innerHeight / 2;
        if (rect?.y) {
          document!.querySelector('#scroll-area')!.scrollTop =
            rect.y - offset + 100;
        }
      }, 0);
    }
  }

  async reload() {
    this.page = 0;
    this.fetch$.next(1);

    setTimeout(() => {
      this.updateQueryParams('s');
    }, 0);
  }

  setLocation(geocodeResult: GeocodeResult) {
    this.selectedLocation = geocodeResult;
    this.updateQueryParams('location', JSON.stringify(geocodeResult));
    this.reload();
  }

  displayLocationFn(result: GeocodeResult): string {
    return result && result.formatted_address ? result.formatted_address : '';
  }

  getRegionalFilters(props: {
    radius: number | undefined | null;
    location: GeocodeResult | undefined;
  }) {
    let filters: (string | string[])[] = [];
    if ((!props.radius || props.radius == 0) && props.location) {
      const canton = props.location.address_components.find(x =>
        x.types.includes(PlaceType2.administrative_area_level_1)
      )?.long_name;

      const city =
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.locality)
        )?.long_name ||
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.administrative_area_level_2)
        )?.long_name;

      filters = [
        canton
          ? [`canton:${canton}`, `canton:${canton?.replace('Kanton ', '')}`]
          : [],
      ];
      if (city) filters.push(`city:${city}`);
    }

    return filters;
  }

  getFilters() {
    let levels = this.levelsControl.value || [];
    levels = this.schoolService.getZyklusLevelsFilter(levels);

    const levelDescriptions = this.levelDescriptionsControl.value || [];
    const availability =
      this.availabilityControl.value?.map(v => `${v}:true`) || [];

    const radius = this.radiusControl.value;
    const regionalFilters = this.getRegionalFilters({
      radius,
      location: this.selectedLocation,
    });

    const filters = [
      ['isPublic:true'],
      levels.map(t => `jobFunctions.jobLevels:${t}`),
      levelDescriptions.map(t => `jobFunctions.jobLevelDescriptions:${t}`),
      ...regionalFilters,
      availability,
    ];

    return filters;
  }

  getRadius() {
    return this.radiusControl.value || undefined;
  }

  getLatLng() {
    if (this.selectedLocation?.geometry?.location) {
      const { lat, lng } = this.selectedLocation.geometry.location;
      return `${lat}, ${lng}`;
    }

    return undefined;
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  async removeFilter(control: FormControl, value: any, key: string) {
    if (Array.isArray(control.value)) {
      const i = control.value.indexOf(value);
      if (i > -1) control.value.splice(i, 1);
    } else {
      control.setValue('');
    }

    if (control === this.locationControl) {
      this.selectedLocation = undefined;
    }

    await this.updateQueryParams(key);
    this.reload();
  }

  async onScroll() {
    if (this.page < this.maxPage) {
      this.page++;
      await this.updateQueryParams('page', this.page);
      this.fetch$
        .pipe(
          switchMap(() =>
            from(
              this.profileService.search(this.searchControl.value || '', {
                facetFilters: this.getFilters(),
                aroundRadius: this.getRadius(),
                aroundLatLng: this.getLatLng(),
                page: this.page,
                hitsPerPage: this.defaultHitsPerPage,
              })
            ).pipe(
              map(r => r.hits),
              filter(r => r.length > 0)
            )
          )
        )
        .subscribe(async results => {
          this.profiles = [...this.profiles, ...results];
        });
    }
  }

  async navigate(event: Event, profile: Hit<Profile>, index: number) {
    event.preventDefault();
    await this.updateQueryParams('s', index);
    await this.router.navigate(
      [
        this.currentUserId === profile.objectID ? '/me' : '/profile',
        profile.objectID,
      ],
      {
        queryParamsHandling: 'merge',
      }
    );
  }

  private async updateQueryParams(
    key: string,
    value?: string | number | boolean
  ) {
    const queryParams = {} as any;
    queryParams[key] = value;
    queryParams['page'] = this.page;

    return await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams,
    });
  }
}
