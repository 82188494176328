import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  from,
  map,
  Observable,
  ReplaySubject,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import {
  School,
  SchoolLevels,
  SchoolLevelDescriptions,
  SchoolTypes,
} from '../../types/school';
import { SearchResponse, Hit } from '@algolia/client-search';
import { LocationsService } from '../../services/locations.service';
import { GeocodeResult, PlaceType2 } from '@googlemaps/google-maps-services-js';
import { SchoolService } from '../../services/school.service';
import { PublicToolbarService } from '../../services/public-toolbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OrderProcessComponent } from 'src/app/dialogs/order-process/order-process.component';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss'],
})
export class SchoolsComponent implements OnInit {
  page = 0;
  maxPage = 0;
  readonly defaultHitsPerPage = 32;

  readonly possibleRadius = [20, 30, 50, 100];

  showFilters = false;

  fetch$ = new ReplaySubject(1);
  schools: Hit<School>[] = [];
  schools$: Observable<SearchResponse<School>> = EMPTY;
  locations$: Observable<GeocodeResult[]> = EMPTY;
  isAuthorized$: Observable<boolean> = EMPTY;

  selectedLocation?: GeocodeResult;
  place?: any;

  searchControl = new FormControl<string>('');
  locationControl = new FormControl<string>('');
  radiusControl = new FormControl<number | undefined>(undefined);

  typesControl = new FormControl<(typeof SchoolTypes)[number][]>([]);
  levelsControl = new FormControl<(typeof SchoolLevels)[number][]>([]);
  levelDescriptionsControl = new FormControl<
    (typeof SchoolLevelDescriptions)[number][]
  >([]);

  schoolTypes = SchoolTypes;
  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;

  constructor(
    private locationsService: LocationsService,
    private schoolService: SchoolService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toolbarService: PublicToolbarService,
    private dialog: MatDialog,
    profileService: ProfileService
  ) {
    this.toolbarService.set({ showBoth: true });

    this.isAuthorized$ = profileService.isAuthorized();

    this.schools$ = this.fetch$.pipe(
      switchMap(() =>
        from(
          this.schoolService.search(this.searchControl.value || '', {
            facetFilters: this.getFilters(),
            aroundRadius: this.getRadius(),
            aroundLatLng: this.getLatLng(),
            page: this.page,
            hitsPerPage: this.defaultHitsPerPage,
          })
        )
      )
    );

    this.schools$.subscribe(result => {
      this.maxPage = result.nbPages - 1;
      this.schools = result.hits;
    });

    this.locations$ = this.locationControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      filter(query => !!query && query.length > 2),
      switchMap(value => this.locationsService.searchAddress(value!)),
      filter(r => r.status === 'OK'),
      map(result => result.results)
    );

    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        skip(1),
        debounceTime(300),
        distinctUntilChanged(),
        tap(value => this.updateQueryParams('query', value!))
      )
      .subscribe(() => this.reload());

    this.radiusControl.valueChanges
      .pipe(
        tap(value => this.updateQueryParams('radius', JSON.stringify(value)))
      )
      .subscribe(() => this.reload());

    this.typesControl.valueChanges
      .pipe(
        tap(value => this.updateQueryParams('types', JSON.stringify(value)))
      )
      .subscribe(() => this.reload());

    this.levelsControl.valueChanges
      .pipe(
        tap(value => this.updateQueryParams('levels', JSON.stringify(value)))
      )
      .subscribe(() => this.reload());

    this.levelDescriptionsControl.valueChanges
      .pipe(
        tap(value =>
          this.updateQueryParams('levelDescriptions', JSON.stringify(value))
        )
      )
      .subscribe(() => this.reload());
  }

  async ngOnInit() {
    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
    if (queryParamMap.get('location')) {
      this.selectedLocation = JSON.parse(
        queryParamMap.get('location')!
      ) as GeocodeResult;
      this.locationControl.setValue(queryParamMap.get('location'));
    }

    if (queryParamMap.get('query')) {
      this.searchControl.setValue(queryParamMap.get('query'));
    }

    if (queryParamMap.get('radius')) {
      this.radiusControl.setValue(parseInt(queryParamMap.get('radius')!));
    }

    if (queryParamMap.get('levels')) {
      this.levelsControl.setValue(JSON.parse(queryParamMap.get('levels')!));
    }

    if (queryParamMap.get('levelDescriptions')) {
      this.levelDescriptionsControl.setValue(
        JSON.parse(queryParamMap.get('levelDescriptions')!)
      );
    }

    this.fetch$.next(1);

    const page = parseInt(queryParamMap.get('page')!) || 0;
    while (this.page !== page) {
      await this.onScroll();
    }

    if (queryParamMap.get('s')) {
      const s = queryParamMap.get('s');
      setTimeout(() => {
        const rect = document!.querySelector(`#s${s}`)?.getBoundingClientRect();
        const offset = window.innerHeight / 2;
        if (rect?.y) {
          document!.querySelector('#scroll-area')!.scrollTop =
            rect.y - offset + 100;
        }
      }, 0);
    }
  }

  reload() {
    this.page = 0;
    this.fetch$.next(1);

    setTimeout(() => {
      this.updateQueryParams('s');
    }, 0);
  }

  setLocation(geocode: GeocodeResult) {
    this.selectedLocation = geocode;
    this.updateQueryParams('location', JSON.stringify(geocode));
    this.reload();
  }

  displayLocationFn(result: GeocodeResult): string {
    return result && result.formatted_address ? result.formatted_address : '';
  }

  getRegionalFilters(props: {
    radius: number | undefined | null;
    location: GeocodeResult | undefined;
  }) {
    let filters: (string | string[])[] = [];
    if ((!props.radius || props.radius == 0) && props.location) {
      const canton = props.location.address_components.find(x =>
        x.types.includes(PlaceType2.administrative_area_level_1)
      )?.long_name;

      const city =
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.locality)
        )?.long_name ||
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.administrative_area_level_2)
        )?.long_name;

      filters = [
        canton
          ? [
              `address.canton:${canton}`,
              `address.canton:${canton?.replace('Kanton ', '')}`,
            ]
          : [],
      ];
      if (city) filters.push(`address.city:${city}`);
    }

    return filters;
  }

  getFilters() {
    let levels = this.levelsControl.value || [];
    levels = this.schoolService.getZyklusLevelsFilter(levels);

    const types = this.typesControl.value || [];
    const levelDescriptions = this.levelDescriptionsControl.value || [];

    const radius = this.radiusControl.value;
    const regionalFilters = this.getRegionalFilters({
      radius,
      location: this.selectedLocation,
    });

    const filters = [
      ['isPublic:true'],
      ...regionalFilters,
      types.map(t => `type:${t}`),
      levels.map(t => `levels:${t}`),
      levelDescriptions.map(t => `levelDescriptions:${t}`),
    ];

    return filters;
  }

  getRadius() {
    return this.radiusControl.value || undefined;
  }

  getLatLng() {
    if (this.selectedLocation?.geometry?.location) {
      const { lat, lng } = this.selectedLocation.geometry.location;
      return `${lat}, ${lng}`;
    }

    return undefined;
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  async removeFilter(control: FormControl, value: any, key: string) {
    if (Array.isArray(control.value)) {
      const i = control.value.indexOf(value);
      if (i > -1) control.value.splice(i, 1);
    } else {
      control.setValue('');
    }

    if (control === this.locationControl) {
      this.selectedLocation = undefined;
    }

    await this.updateQueryParams(key);
    this.reload();
  }

  async onScroll() {
    if (this.page < this.maxPage) {
      this.page++;
      await this.updateQueryParams('page', this.page);
      this.fetch$
        .pipe(
          switchMap(() =>
            from(
              this.schoolService.search(this.searchControl.value || '', {
                facetFilters: this.getFilters(),
                aroundRadius: this.getRadius(),
                aroundLatLng: this.getLatLng(),
                page: this.page,
                hitsPerPage: this.defaultHitsPerPage,
              })
            ).pipe(
              map(r => r.hits),
              filter(r => r.length > 0)
            )
          )
        )
        .subscribe(results => {
          this.schools = [...this.schools, ...results];
        });
    }
  }

  async navigate(event: Event, school: Hit<School>, index: number) {
    event.preventDefault();
    await this.updateQueryParams('s', index);
    await this.router.navigate(['/schools', school.objectID], {
      queryParamsHandling: 'merge',
    });
  }

  private async updateQueryParams(
    key: string,
    value?: string | number | boolean
  ) {
    const queryParams = {} as any;
    queryParams[key] = value;
    queryParams['page'] = this.page;
    return this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams,
    });
  }

  openOrderDialog() {
    this.dialog.open(OrderProcessComponent, {
      minWidth: '100vw',
      minHeight: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
    });
  }
}
