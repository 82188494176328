export const environment = {
  name: 'prodcution',
  useEmulators: false,
  production: true,
  homepageBaseUrl: 'https://bildigsnetz.ch',
  storageBaseUrl:
    'https://firebasestorage.googleapis.com/v0/b/bildigsnetz.appspot.com/o',
  firebase: {
    apiKey: 'AIzaSyAxhONbqZV5Ro3AJy-HFFELZwZGn7Ro640',
    authDomain: 'bildigsnetz.firebaseapp.com',
    projectId: 'bildigsnetz',
    storageBucket: 'bildigsnetz.appspot.com',
    messagingSenderId: '631359517628',
    appId: '1:631359517628:web:a4bd4242dbf4f7a3dcf1b9',
    locationId: 'europe-west3',
    measurementId: 'G-8E1WL22KD1',
  },
  algolia: {
    appId: 'E20PHN0XAV',
    api_key: 'f7d2ffbdcbe3c8fc0392dd72502cb63f',
  },
  gmaps: {
    apiKey: 'AIzaSyAxhONbqZV5Ro3AJy-HFFELZwZGn7Ro640',
  },
  sentry: {
    dsn: 'https://0363883f163e5d59001514ae652ab04a@o4505048277057536.ingest.sentry.io/4505742792130560',
  },
};
