<div
  class="flex flex-col h-[222px] md:h-[282px] lg:h-[258px] border-solid border-[#B5B6C1] border rounded-2xl hover:border-[#fff] cursor-pointer profile-card p-4 md:p-6">
  <div class="flex justify-between">
    <app-round-image
      class="block md:hidden"
      size="60"
      [src]="getImageUrl(profile)" />
    <app-round-image
      class="hidden md:block"
      size="80"
      [src]="getImageUrl(profile)" />
    <div class="flex gap-3">
      @if (profile.interestedInFulltimeJobs) {
        <div
          class="h-10 w-10 bg-[#ECF4FF] rounded-full flex justify-center items-center">
          <mat-icon class="material-symbols-rounded blue-icon"> work </mat-icon>
        </div>
      }
      @if (profile.interestedInSubstituteJobs) {
        <div
          class="h-10 w-10 bg-[#F5DFE1] rounded-full flex justify-center items-center">
          <mat-icon class="material-symbols-rounded red-icon">
            work_history
          </mat-icon>
        </div>
      }
    </div>
  </div>
  <div class="flex flex-col pt-3">
    <span class="font-bold text-2xl">{{ profile.name }}</span>
    <span class="text-base text-bn-dark-grey">{{ profile.city }}</span>
  </div>
  <div class="flex flex-col pt-3 md:pt-4">
    @for (
      function of profile.jobFunctions | slice: 0 : 2;
      track function;
      let last = $last
    ) {
      <div class="text-base text-bn-dark-grey w-full">
        <div class="flex">
          <div class="line-clamp-1 text-ellipsis w-fit">
            <span>{{ getJobTitle(function) }}</span>
          </div>
          @if (last && profile.jobFunctions.length > 2) {
            <span class="pl-1"> +{{ profile.jobFunctions.length - 2 }} </span>
          }
        </div>
      </div>
    }
  </div>
</div>
