import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  Firestore,
  getDoc,
  limit,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { from, map, Observable } from 'rxjs';
import { Job, JobActivityRange } from '../types/job';
import { searchClient } from '../core/algolia';
import { SearchIndex } from 'algoliasearch';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { DatePipe } from '@angular/common';
import { hasObjectOnlyEmptyProps } from '../core/helpers';
import { SchoolLevelDescriptions, SchoolLevels } from '../types/school';

type FacetFilters =
  | string
  | readonly string[]
  | readonly (string | readonly string[])[]
  | undefined;
type AroundRadius = number | 'all' | undefined;
type AroundLatLng = string | undefined;

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private index: SearchIndex;
  private upsertJobFunc: (data: { job: Partial<Job> }) => Observable<Job>;
  private publishJobFunc: (data: { id: string }) => Observable<Job>;
  private closeJobFunc: (data: {
    id: string;
    reason?: string;
  }) => Observable<Job>;
  private discardJobFunc: (data: { id: string }) => Observable<Job>;

  constructor(
    private firestore: Firestore,
    private analytics: Analytics,
    private datePipe: DatePipe,
    functions: Functions
  ) {
    this.index = searchClient.initIndex('jobs');
    this.upsertJobFunc = httpsCallableData(functions, 'upsertjob', {});
    this.publishJobFunc = httpsCallableData(functions, 'publishjob', {});
    this.closeJobFunc = httpsCallableData(functions, 'closejob', {});
    this.discardJobFunc = httpsCallableData(functions, 'discardjob', {});
  }

  create(job: Partial<Job>) {
    logEvent(this.analytics, 'job_create');
    return this.upsertJobFunc({ job });
  }

  update(id: string, job: Partial<Job>) {
    logEvent(this.analytics, 'job_update', { jobId: id });
    return this.upsertJobFunc({ job: { ...job, id } });
  }

  getById(id: string) {
    const docRef = doc(this.firestore, `jobs/${id}`);
    return from(getDoc(docRef)).pipe(map(d => d.data() as Job));
  }

  getBySchoolRoot(rootId: string, isPublic: boolean, isClosed: boolean) {
    const colRef = query(
      collection(this.firestore, 'jobs'),
      where('schoolRootId', '==', rootId),
      where('isPublic', '==', isPublic),
      where('isClosed', '==', isClosed)
    );
    return collectionData(colRef, { idField: 'id' }) as Observable<Job[]>;
  }

  getBySchool(schoolId: string, isPublic = true, isClosed = false) {
    const colRef = query(
      collection(this.firestore, 'jobs'),
      where('schoolId', '==', schoolId),
      where('isPublic', '==', isPublic),
      where('isClosed', '==', isClosed)
    );
    return collectionData(colRef, { idField: 'id' }) as Observable<Job[]>;
  }

  discard(id: string) {
    logEvent(this.analytics, 'job_discard', { jobId: id });
    return this.discardJobFunc({ id });
  }

  publish(id: string) {
    logEvent(this.analytics, 'job_publish', { jobId: id });
    return this.publishJobFunc({ id });
  }

  close(id: string, reason?: string) {
    logEvent(this.analytics, 'job_close', { jobId: id, reason });
    return this.closeJobFunc({ id, reason });
  }

  search(
    query: string,
    options: {
      facetFilters?: FacetFilters;
      aroundRadius?: AroundRadius;
      aroundLatLng?: AroundLatLng;
      page?: number;
      hitsPerPage?: number;
      filters?: string;
      maxFacetHits?: number;
    }
  ) {
    return this.index.search<Job>(query, { ...options, cacheable: false });
  }

  getLatest(count = 3) {
    const colRef = query(
      collection(this.firestore, 'jobs'),
      where('isPublic', '==', true),
      where('isClosed', '==', false),
      orderBy('modifiedOn', 'desc'),
      limit(count)
    );
    return collectionData(colRef, { idField: 'id' }) as Observable<Job[]>;
  }

  isWorkdayMatrixEmpty(job: Job) {
    if (job.workdayMatrix?.length) {
      return job.workdayMatrix.every(day => !day.am && !day.pm);
    }

    return true;
  }

  getFormattedSubstitutionPeriodLong(job: Job) {
    const format = 'd. MMM y';

    return `Zeitraum: ${this.getFormattedSubstitutionPeriodBase(job, format)}`;
  }

  getFormattedSubstitutionPeriodShort(job: Job) {
    const format = 'dd.MM.yy';

    return `${this.getFormattedSubstitutionPeriodBase(job, format)}`;
  }

  private getFormattedSubstitutionPeriodBase(job: Job, format: string) {
    const from = job.isDurationStartNow
      ? 'Sofort'
      : this.datePipe.transform(job.durationFrom, format);
    const to = this.datePipe.transform(job.durationTo, format) || '';

    return to ? `${from} - ${to}` : from;
  }

  getFormattedActivityRange(
    rangeInPercentage: JobActivityRange | null | undefined,
    rangeInHours: JobActivityRange | null | undefined
  ) {
    const pensumText = 'Pensum';
    const lektionenText = 'Lektionen';

    if (rangeInPercentage && !hasObjectOnlyEmptyProps(rangeInPercentage)) {
      const { from, to } = rangeInPercentage;
      return from === to
        ? `${pensumText}: ${from}%`
        : `${pensumText}: ${from} - ${to}%`;
    }

    if (rangeInHours && !hasObjectOnlyEmptyProps(rangeInHours)) {
      const { from, to } = rangeInHours;
      return from === to
        ? `${pensumText}: ${from} ${lektionenText}`
        : `${pensumText}: ${from} - ${to} ${lektionenText}`;
    }

    return '';
  }

  getLevelsOrder(levels: (typeof SchoolLevels)[number][]) {
    const levelsCopy = [...levels] as string[];
    if (
      levelsCopy.includes('Unterstufe') &&
      levelsCopy.includes('Mittelstufe')
    ) {
      levelsCopy.splice(levelsCopy.indexOf('Unterstufe'), 1);
      levelsCopy.splice(levelsCopy.indexOf('Mittelstufe'), 1, 'Primarstufe');
    }

    if (
      levelsCopy.includes('Kindergarten') &&
      levelsCopy.includes('Unterstufe')
    ) {
      levelsCopy.splice(levelsCopy.indexOf('Kindergarten'));
      levelsCopy.splice(levelsCopy.indexOf('Unterstufe'), 1, 'KUST');
    }

    const order = [
      'Zyklus I',
      'Zyklus II',
      'Zyklus III',
      'Kindergarten',
      'Basisstufe',
      'Unterstufe',
      'Mittelstufe',
      'Primarstufe',
      'Sekundarstufe I',
      'Sekundarstufe II',
    ];

    const orderedLevels = levelsCopy.sort(
      (a, b) => order.indexOf(a) - order.indexOf(b)
    ) as string[];

    return orderedLevels;
  }

  getLevelsTitle(
    levels: (typeof SchoolLevels)[number][],
    schoolLevelDescriptions: (typeof SchoolLevelDescriptions)[number][]
  ) {
    const orderedLevels = this.getLevelsOrder(levels);
    const post =
      schoolLevelDescriptions && schoolLevelDescriptions.length > 0
        ? ` | ${schoolLevelDescriptions.join(' - ')}`
        : '';

    return `${orderedLevels.join(' - ')}${post}`;
  }
}
