import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, map, startWith } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job, JobFunctions } from '../../../../types/job';

@Component({
  selector: 'app-job-function-form',
  templateUrl: './job-function-form.component.html',
  styleUrls: ['./job-function-form.component.scss'],
})
export class JobFunctionFormComponent implements OnInit {
  id: string;
  job$: Observable<Job>;
  filteredJobFunctions$: Observable<string[]> = EMPTY;
  loading = false;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return JobFunctions.filter(f => f.toLowerCase().includes(filterValue));
  }

  form = this.fb.group({
    function: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);
  }

  ngOnInit(): void {
    this.job$.subscribe(job => {
      this.form.setValue({
        function: job.function || '',
      } as any);
    });

    this.filteredJobFunctions$ = this.form.controls.function.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  save() {
    this.loading = true;
    const jobPartial = this.form.value as Partial<Job>;
    this.jobService.update(this.id, jobPartial).subscribe(_ => {
      this.loading = false;
      this.router.navigate(['jobs', this.id]);
    });
  }
}
