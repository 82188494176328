<div class="flex justify-center border-b-2">
  @if (config$ | async; as config) {
    <mat-toolbar
      class="sm:py-8 bg-white justify-between px-4 pt-2 md:px-16 w-full max-w-[1724px]">
      <div class="h-auto w-32">
        <a [href]="baseUrl">
          <img src="assets/logo.svg" alt="Logo" />
        </a>
      </div>
      @if (!config.showBoth && !config.showLogin) {
        <div class="flex items-center gap-4">
          <span class="hidden sm:block text-base text-[#B5B6C1]">
            Noch nicht Mitglied bei Bildigsnetz?
          </span>
          <a routerLink="/signup" mat-stroked-button color="primary">
            Mitglied werden
          </a>
        </div>
      }
      @if (!config.showBoth && config.showLogin) {
        <div class="flex items-center gap-4">
          <span class="hidden sm:block text-base text-[#B5B6C1]">
            Du hast bereits ein Konto?
          </span>
          <a routerLink="/signin" mat-stroked-button color="primary"
            >Einloggen</a
          >
        </div>
      }
      @if (config.showBoth) {
        <div class="flex items-center gap-4">
          <a routerLink="/signin" mat-button color="primary">Einloggen</a>
          <a routerLink="/signup" mat-flat-button color="primary">
            Mitglied werden
          </a>
        </div>
      }
    </mat-toolbar>
  }
</div>
