<div class="flex flex-col justify-center min-h-full">
  <div
    class="grid grid-cols-12 col-span-12 h-full gap-y-10 md:gap-y-12 2xl:gap-y-14">
    <div
      class="flex flex-col col-span-12 xl:col-span-5 xl:self-center gap-10 md:gap-12 2xl:gap-14">
      <div class="flex flex-col gap-4 md:gap-6 2xl:gap-7">
        <span class="font-bold text-5xl md:text-7xl xl:text-6xl">
          Bildigsnetz-Abo
        </span>
        <span class="text-3xl md:text-5xl xl:text-4xl">
          Dein All-In-One-Paket
        </span>
      </div>
      <div class="flex flex-col gap-4 md:gap-6 xl:gap-7">
        <span
          class="font-bold text-2xl md:text-4xl xl:text-[45px] text-bn-primary-blue">
          3 Monate kostenlos testen
        </span>
        <span class="text-sm md:text-base text-bn-dark-grey">
          Falls schon ein Schulprofil deiner Schule besteht, erfrage die
          Zugangsberechtigung beim Administrator. Ansonsten erfährst du hier von
          den zahlreichen Vorteilen eines Jahresabos für Schulen.
        </span>
        <app-secondary-button class="w-fit" (onClick)="showPrices()">
          <span class="font-medium px-2">Preisübersicht</span>
        </app-secondary-button>
      </div>
    </div>
    <div
      class="flex flex-col col-span-12 xl:flex-row xl:col-span-6 xl:col-start-7 gap-4">
      <div class="flex flex-col gap-4 w-full xl:self-center">
        <app-order-process-benefit [benefit]="benefits[0]" />
        <app-order-process-benefit [benefit]="benefits[1]" />
      </div>
      <div class="flex flex-col gap-4 w-full">
        <app-order-process-benefit [benefit]="benefits[2]" />
        <app-order-process-benefit [benefit]="benefits[3]" />
      </div>
    </div>
  </div>
</div>
