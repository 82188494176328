import { Component, Input } from '@angular/core';
import { Job } from '../../types/job';
import { getPublicFileUrl } from '../../core/helpers';
import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import { JobService } from 'src/app/services/job.service';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss'],
})
export class JobCardComponent {
  @Input({ required: true }) job!: Job;
  @Input() navigation = false;
  @Input() showApplicants = true;
  hideImage = false;

  constructor(public jobService: JobService) {}

  getAvatarUrl() {
    if (this.job?.schoolId) {
      return getPublicFileUrl('schools', this.job?.schoolId, 'avatar');
    }
    return '';
  }

  getTagText() {
    if (this.job?.subjects?.length) {
      return `${this.job?.function} | ${this.job?.subjects?.join(', ')}`;
    }

    return this.job?.function || '—';
  }

  getTitle(job: Job) {
    return this.jobService.getLevelsTitle(job.schoolLevels || [], []);
  }

  getHumanizedTime() {
    if (this.job?.modifiedOn) {
      const d = Date.parse(this.job.modifiedOn!);
      const str = formatDistanceToNow(d, { addSuffix: true, locale: de });
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return '';
  }

  getJobType() {
    return this.job.isFullTimeJob ? 'Festanstellung' : 'Stellvertretung';
  }

  getActivityRange() {
    return this.jobService.getFormattedActivityRange(
      this.job.activityRangeInPercentage,
      this.job.activityRangeInHours
    );
  }
}
