import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { School } from '../../../../types/school';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'app-school-progress-card',
  templateUrl: './school-progress-card.component.html',
  styleUrls: ['./school-progress-card.component.scss'],
})
export class SchoolProgressCardComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input() buttonDisabled = false;
  @Output() publish = new EventEmitter<void>();

  permissionLink = 'edit/permissions';

  hasAbout = false;
  hasType = false;
  hasInfo = false;
  hasContacts = false;
  hasMedia = false;
  hasPedagogicFocus = false;
  hasDocuments = false;
  hasWeOffer = false;

  checklist: boolean[] = [];
  progressPercentage = 0;

  constructor(private schoolService: SchoolService) {}

  ngOnInit(): void {
    this.permissionLink = `/schools/${this.school.rootId}/edit/permissions`;

    this.checkProgress();
    this.calculateProgressInPercent();
  }

  checkProgress() {
    this.hasAbout = !!this.school.about;
    this.checklist.push(this.hasAbout);

    this.hasInfo = !!this.school.address && !!this.school.countEmployees;
    this.checklist.push(this.hasInfo);

    this.hasType = !!this.school.type && !!this.school.levels;
    this.checklist.push(this.hasType);

    this.hasContacts =
      !!this.school.contacts && this.school.contacts.length > 0;
    this.checklist.push(this.hasContacts);

    this.hasMedia = !!this.school.media && this.school.media.length > 0;
    this.checklist.push(this.hasMedia);

    if (this.school.isRoot) {
      this.hasDocuments = !!this.school.documents;
      this.checklist.push(this.hasDocuments);

      this.hasPedagogicFocus = !!this.school.pedagogicFocus;
      this.checklist.push(this.hasPedagogicFocus);
    }

    if (this.isWeOfferRevelant()) {
      this.hasWeOffer = !!this.school.whatWeOffer;
      this.checklist.push(this.hasWeOffer);
    }
  }

  calculateProgressInPercent() {
    const numberOfCheckedItems = this.checklist.filter(item => item).length;
    const numberOfItems = this.checklist.length;

    this.progressPercentage = Math.ceil(
      (numberOfCheckedItems / numberOfItems) * 100
    );
  }

  onPublish() {
    this.publish.emit();
  }

  isWeOfferRevelant() {
    return this.schoolService.IsWeOfferRelevant(this.school);
  }
}
