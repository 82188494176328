@if (!isOwner) {
  <div class="pb-4">
    <a mat-button routerLink="/schools" queryParamsHandling="preserve">
      <mat-icon class="material-symbols-rounded">keyboard_backspace</mat-icon>
      Alle Ergebnisse
    </a>
  </div>
}

@if (school$ | async; as school) {
  <div class="flex flex-col gap-16 md:gap-24 max-w-[1420px]">
    <app-school-header
      [school]="school"
      [isOwner]="isOwner"></app-school-header>
    <div class="grid grid-cols-12">
      <div
        class="flex flex-col gap-16 md:gap-24 col-span-12 lg:col-start-2 lg:col-span-10">
        @if (!school.isPublic && isOwner) {
          <app-school-progress-card
            class="conditional-hide"
            (publish)="publish(school)"
            [buttonDisabled]="missingFields.length > 0"
            [school]="school"></app-school-progress-card>
        }
        <app-school-intro-card
          class="conditional-hide"
          [school]="school"
          [isOwner]="isOwner"></app-school-intro-card>
        <app-school-info-card
          class="conditional-hide"
          [school]="school"
          [isOwner]="isOwner"></app-school-info-card>
        <app-school-about-card
          class="conditional-hide"
          [school]="school"
          [isOwner]="isOwner"></app-school-about-card>
        <app-school-media-card
          class="conditional-hide"
          [school]="school"
          [isOwner]="isOwner"></app-school-media-card>
        @if (showWeOffer(school)) {
          <app-school-weoffer-card
            class="conditional-hide"
            [school]="school"
            [isOwner]="isOwner"></app-school-weoffer-card>
        }
        @if (school.isRoot) {
          <app-school-focus-card
            class="conditional-hide"
            [school]="school"
            [isOwner]="isOwner"></app-school-focus-card>
        }
        @if (school.isRoot) {
          <app-school-documents-card
            class="conditional-hide"
            [school]="school"
            [isOwner]="isOwner"></app-school-documents-card>
        }
        <app-school-contact-card
          class="conditional-hide"
          [school]="school"
          [isOwner]="isOwner"></app-school-contact-card>
        <app-school-jobs-card
          class="conditional-hide"
          [school]="school"></app-school-jobs-card>
      </div>
    </div>
  </div>
}
