import { Component } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import {
  Auth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
} from '@angular/fire/auth';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { PublicToolbarService } from '../../services/public-toolbar.service';
import { environment } from '../../../environments/environment';
import {
  firebaseErrorCodes,
  isFirebaseErrorKnown,
  mapFirebaseErrorCodeToUserMessage,
} from 'src/app/core/firebaseErrorMapper';
import { setUserId, Analytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  baseUrl = environment.homepageBaseUrl;
  signupError = '';
  loading = false;

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    acceptNewsletter: [false, []],
  });

  constructor(
    private auth: Auth,
    private router: Router,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private analytics: Analytics,
    toolbarService: PublicToolbarService
  ) {
    toolbarService.set({ showLogin: true });
  }

  async signupWithGoogle() {
    try {
      const newsletter = this.form.get('acceptNewsletter')?.value;
      localStorage.setItem('OPTIN_NEWSLETTER', newsletter ? '1' : '0');
      const result = await signInWithPopup(this.auth, new GoogleAuthProvider());
      setUserId(this.analytics, result.user.uid);
      await this.reload();
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (
          error.code === firebaseErrorCodes.accountExistsWithDifferentCredential
        ) {
          this.notificationService.error(
            mapFirebaseErrorCodeToUserMessage(error.code)
          );
          return;
        }
      }

      // Unknown errors
      this.notificationService.error(
        'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.'
      );

      this.loading = false;
    }
  }

  async signupWithEmail() {
    this.loading = true;

    const email = this.form.get('email')?.value;
    const password = (this.form.get('passwordFields')?.value as any)
      ?.password as string;
    const newsletter = this.form.get('acceptNewsletter')?.value;

    try {
      localStorage.setItem('OPTIN_NEWSLETTER', newsletter ? '1' : '0');

      const result = await createUserWithEmailAndPassword(
        this.auth,
        email!,
        password!
      );
      await sendEmailVerification(result.user);
      setUserId(this.analytics, result.user.uid);

      await this.reload();
      this.loading = false;
    } catch (error) {
      // Known errors
      if (isFirebaseErrorKnown(error)) {
        this.signupError = mapFirebaseErrorCodeToUserMessage(
          (error as FirebaseError).code
        );
      }

      // Unknown errors
      if (!this.signupError) {
        this.signupError =
          'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.';
      }

      this.loading = false;
    }
  }

  async reload() {
    // reloads current page to trigger logic of router
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate(['/welcome']);
  }
}
