import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import { Profile, ProfileJobFunction } from '../../../../types/profile';
import { JobFunctions } from '../../../../types/job';
import {
  SchoolLevelDescriptions,
  SchoolLevels,
} from '../../../../types/school';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GeocodeResult } from '@googlemaps/google-maps-services-js';
import { LocationsService } from '../../../../services/locations.service';
import {
  NotificationSubscription,
  JobSubscriptionData,
} from '../../../../types/notification';

type SelectedJobFunction = {
  value: string;
  jobLevelsControl: FormControl<
    null | string[] | (typeof SchoolLevels)[number][]
  >;
  jobLevelDescriptionsControl: FormControl<
    null | string[] | (typeof SchoolLevelDescriptions)[number][]
  >;
  inStudyControl: FormControl<boolean | null | undefined>;
};

@Component({
  selector: 'app-profile-job-form',
  templateUrl: './profile-job-form.component.html',
  styleUrls: ['./profile-job-form.component.scss'],
})
export class ProfileJobFormComponent implements OnInit {
  id?: string;
  profile?: Profile;
  notifcationSubscription?: NotificationSubscription<JobSubscriptionData>;
  iconColor = 'secondary';
  readonly possibleRadius = [20, 30, 50, 100];

  selectedJobFunctions: SelectedJobFunction[] = [];
  locations$: Observable<GeocodeResult[]> = EMPTY;
  selectedLocation?: GeocodeResult;

  form = this.fb.group({
    jobFunctions: ['', []],
    interestedInFulltimeJobs: [false, []],
    interestedInSubstituteJobs: [false, []],
    isEmailNotificationEnabled: [false, []],
    isWhatsappNotificationEnabled: [false, []],
    email: ['', []],
    phone: ['', []],
    location: ['', []],
    radius: [30, []],
    levels: [[] as (typeof SchoolLevels)[number][], []],
    employmentType: ['both', []],
  });

  filteredJobFunctions$: Observable<(typeof JobFunctions)[number][]> = EMPTY;
  reloadFilteredJobFunctions$ = new ReplaySubject(1);

  jobFunctions = JobFunctions;
  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;

  constructor(
    private profileService: ProfileService,
    private locationsService: LocationsService,
    private router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
  }

  ngOnInit() {
    this.profileService.getMe(false).subscribe(profile => {
      this.profile = profile;
      this.form.controls.interestedInFulltimeJobs.setValue(
        profile.interestedInFulltimeJobs || false
      );
      this.form.controls.interestedInSubstituteJobs.setValue(
        profile.interestedInSubstituteJobs || false
      );

      this.selectedJobFunctions = profile.jobFunctions.map(j => ({
        value: j.name,
        inStudyControl: new FormControl(j.inStudy),
        jobLevelsControl: new FormControl(j.jobLevels || []),
        jobLevelDescriptionsControl: new FormControl(
          j.jobLevelDescriptions || []
        ),
      }));
    });

    this.filteredJobFunctions$ = this.reloadFilteredJobFunctions$.pipe(
      switchMap(() =>
        from(
          this.form.controls.jobFunctions.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            map(value =>
              value && value.length > 0 ? this.filter(value || '') : []
            )
          )
        )
      )
    );

    this.locations$ = this.form.controls.location.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      filter(query => !!query && query.length > 2),
      switchMap(value => this.locationsService.searchAddress(value!)),
      filter(r => r.status === 'OK'),
      map(result => result.results)
    );

    this.form.controls.jobFunctions.valueChanges.subscribe(value => {
      this.iconColor = value && value.length > 2 ? 'primary' : 'secondary';
    });

    this.reloadFilteredJobFunctions$.next(1);
  }

  remove(index: number) {
    this.selectedJobFunctions.splice(index, 1);
    this.reloadFilteredJobFunctions$.next(1);
  }

  selectJobFunction(jobFunction: string) {
    this.form.controls.jobFunctions?.setValue('');
    if (this.selectedJobFunctions.some(j => j.value === jobFunction)) return;
    this.selectedJobFunctions.push({
      value: jobFunction,
      inStudyControl: new FormControl(false),
      jobLevelsControl: new FormControl([]),
      jobLevelDescriptionsControl: new FormControl([]),
    });

    this.reloadFilteredJobFunctions$.next(1);
  }

  onSelectJobFunction(event: MatAutocompleteSelectedEvent) {
    const jobFunction = event.option.value as string;
    this.selectJobFunction(jobFunction);
  }

  add() {
    const value = this.form.controls.jobFunctions.value as string;
    if (value && value.length > 2) {
      this.selectJobFunction(value);
    }
  }

  save() {
    const jobFunctions: ProfileJobFunction[] = this.selectedJobFunctions.map(
      j => ({
        name: j.value,
        jobLevels: (j.jobLevelsControl.value ||
          []) as (typeof SchoolLevels)[number][],
        jobLevelDescriptions: (j.jobLevelDescriptionsControl.value ||
          []) as (typeof SchoolLevelDescriptions)[number][],
        inStudy: !!j.inStudyControl.value,
      })
    );

    const interestedInFulltimeJobs =
      this.form.controls.interestedInFulltimeJobs.value || false;

    const interestedInSubstituteJobs =
      this.form.controls.interestedInSubstituteJobs.value || false;

    this.profileService
      .update({
        jobFunctions,
        interestedInFulltimeJobs,
        interestedInSubstituteJobs,
      })
      .subscribe(_ => {
        this.router.navigate(['me', this.id]);
      });
  }

  showJobLevelDescriptionsField(selectedJobFunction: SelectedJobFunction) {
    const jobLevels = selectedJobFunction.jobLevelsControl.value;
    return !!jobLevels && jobLevels.some(l => l === 'Sekundarstufe II');
  }

  private filter(value: string) {
    const filterValue = value.toLowerCase();
    const selectedJobFunctions = this.selectedJobFunctions?.map(j => j.value);

    return this.jobFunctions
      .filter(jobFunction => !selectedJobFunctions.includes(jobFunction))
      .filter(jobFunction => jobFunction.toLowerCase().includes(filterValue));
  }
}
