import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SchoolService } from 'src/app/services/school.service';
import {
  AppliedCouponType,
  CheckoutRequest,
  SubscriptionType,
} from 'src/app/types/subscription';

@Component({
  selector: 'app-ordering-overview',
  templateUrl: './ordering-overview.component.html',
  styleUrls: ['./ordering-overview.component.scss'],
})
export class OrderingOverviewComponent {
  @Input({ required: true }) form: FormGroup = new FormGroup({});
  @Input() subscriptionType: SubscriptionType = 'SCHOOL_S';
  @Input() addressData: {
    organization: string;
    name: string;
    street: string;
    streetNumber: string;
    zipcode: string;
    city: string;
    email: string;
  } | null = null;
  @Output() next = new EventEmitter<void>();
  @Output() prev = new EventEmitter<void>();

  appliedCouponType: AppliedCouponType = null;

  basicOfferSpecs: {
    schoolSize: string;
    trialPeriod: string;
    price: number | null;
    jobsPrice: number | null;
  } | null = null;

  hasCodeError = false;

  codeForm = this.fb.group({
    code: [''],
  });

  constructor(
    private fb: FormBuilder,
    private schoolService: SchoolService
  ) {}

  getOfferConditions() {
    const conditions: {
      title: string;
      description: string;
    }[] = [];

    // Subscription type
    const subTitle = `${this.subscriptionType !== 'SCHOOL_CUSTOM' ? 'Gewähltes ' : ''}Abonnement:`;

    conditions.push({
      title: subTitle,
      description: 'Bildigsnetz Jahresabo',
    });

    // School size
    let sizeDescription = '';

    switch (this.subscriptionType) {
      case 'SCHOOL_CUSTOM':
        sizeDescription = 'Kantone und Gemeinden';
        break;
      case 'SCHOOL_L':
        sizeDescription = 'Über 200 Lernende';
        break;
      default:
        sizeDescription = 'Unter 200 Lernende';
        break;
    }

    conditions.push({
      title: 'Schulgrösse:',
      description: sizeDescription,
    });

    // Trial period
    if (
      this.subscriptionType !== 'SCHOOL_CUSTOM' &&
      this.appliedCouponType === null
    ) {
      const trialFromFormatted = this.getSwissFormattedDate(new Date());

      const trialToDate = new Date();
      trialToDate.setMonth(trialToDate.getMonth() + 3);
      trialToDate.setDate(trialToDate.getDate() - 1);
      const trialToFormatted = this.getSwissFormattedDate(trialToDate);

      conditions.push({
        title: 'Probeabo aktiv:',
        description: `${trialFromFormatted} - ${trialToFormatted}`,
      });
    }

    // Price
    let priceDescription = '';
    const price = this.subscriptionType === 'SCHOOL_L' ? 1290 : 990;

    if (this.subscriptionType !== 'SCHOOL_CUSTOM') {
      switch (this.appliedCouponType) {
        case 'LU_24':
          priceDescription = `<strong>
            Spezialkonditionen Kooperation Kanton Luzern:
          </strong><br />
          <ul class="inner-html-list">
            <li>Schulprofil: Auf unbestimmte Dauer kostenlos</li>
            <li>
              Stellenportal: Kalenderjahr 2024 kostenlos, danach
              ${price / 2} Fr. jährlich pro Schuleinheit (inkl. MwSt)
            </li>
          </ul>`;
          break;
        case 'PHZH_24':
          priceDescription = `<strong>
            Spezialkonditionen Kooperation PHZH:
          </strong><br />
          <ul class="inner-html-list">
            <li>Kalenderjahr 2024 kostenlos</li>
            <li>Ab 2025: 25% Rabatt auf Jahresabo pro Schuleinheit
             (${Math.ceil(price * 0.75)} Fr. anstatt ${price} Fr.)</li>
          </ul>`;
          break;
        default:
          priceDescription = `3 Monate* kostenlos testen.<br />
          Danach
            <strong>
              ${price} Fr. jährlich pro Schuleinheit inkl. MwSt**.
            </strong>`;
          break;
      }
    } else {
      priceDescription =
        'Wir kontaktieren dich für ein unverbindliches Spezialangebot.';
    }

    conditions.push({
      title: 'Preis:',
      description: priceDescription,
    });

    // Further notes
    if (this.appliedCouponType !== null) {
      let notesDescription = '';

      switch (this.appliedCouponType) {
        case 'LU_24':
          notesDescription = `Ab 2025 wird der Teil Stellenportal kostenpflichtig.<br />
          Bitte budgetiere ${price / 2} Fr. für 2025 oder kündige diesen Teil
          im Verlauf von 2024 jederzeit ohne Begründung mit einer Mail an
          <a class="inner-html-link" href="mailto:info@bildigsnetz.ch">info&#64;bildigsnetz.ch</a>.*`;
          break;
        default:
          notesDescription = `Bitte budgetiere ${Math.ceil(price * 0.75)} Fr. für 2025.`;
          break;
      }

      conditions.push({
        title: 'Hinweis:',
        description: notesDescription,
      });
    }

    return conditions;
  }

  verifyAndAddCode() {
    if (this.codeForm.value.code === 'DVS_Kanton_Luzern_2024') {
      this.appliedCouponType = 'LU_24';
      this.codeForm.controls['code'].disable();
      this.hasCodeError = false;
    } else if (this.codeForm.value.code === 'PHZH_Kooperationsschule_2024') {
      this.appliedCouponType = 'PHZH_24';
      this.codeForm.controls['code'].disable();
      this.hasCodeError = false;
    } else {
      this.codeForm.reset();
      this.hasCodeError = true;
    }
  }

  onPrev() {
    this.prev.emit();
  }

  onSubmit() {
    const request = {
      ...this.addressData,
      subscriptionType: this.subscriptionType,
      appliedCouponType: this.appliedCouponType,
    } as CheckoutRequest;

    this.schoolService
      .purchaseSubscription(request)
      .subscribe(() => this.next.emit());
  }

  private getSwissFormattedDate(date: Date) {
    return date.toLocaleDateString('de-CH', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }
}
