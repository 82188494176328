<div
  class="flex flex-col border-[#B5B6C1] border rounded-2xl h-full w-full p-5"
  [ngClass]="{
    'border-solid': job.isPublic,
    'border-dotted': !job.isPublic,
    'job-card': !navigation
  }">
  <div class="flex relative">
    <div class="border border-[#eaebef] rounded-xl">
      <div
        class="h-20 w-20 rounded-xl bg-center bg-no-repeat bg-contain"
        [ngStyle]="{
          'background-image':
            'url(' +
            getAvatarUrl() +
            '), url(/assets/defaults/school_avatar.svg)'
        }"></div>
    </div>
    @if (job.isPublic && !job.isClosed) {
      <span class="absolute top-0 right-0 bright-font text-sm">
        <span class="primary-color">•</span>
        {{ getHumanizedTime() }}
      </span>
    }
  </div>
  <div class="flex flex-col">
    <div class="pt-8 pb-7">
      <app-tag [text]="getTagText()"></app-tag>
    </div>
    <div class="h-[6.2rem]">
      <span class="font-bold text-base mb-1 line-clamp-2 text-ellipsis">
        {{ job.schoolName }}
      </span>
      <span class="text-[0.875rem] mb-1 line-clamp-1 text-ellipsis">
        {{ getTitle(job) }}
      </span>
      <span class="text-[0.875rem] bright-font line-clamp-1 text-ellipsis">
        {{ job.schoolAddress?.city || '' }}
      </span>
    </div>
    <div class="flex flex-col">
      <span class="line-clamp-1 text-ellipsis">{{ getJobType() }}</span>
      @if (job.isFullTimeJob) {
        <span class="line-clamp-1 text-ellipsis">
          {{ getActivityRange() }}
        </span>
      }
      @if (!job.isFullTimeJob) {
        <span class="line-clamp-1 text-ellipsis">
          {{ this.jobService.getFormattedSubstitutionPeriodShort(job) }}
        </span>
      }
    </div>
    @if (navigation) {
      <div class="flex flex-col">
        <div class="pt-4 pb-4">
          <mat-divider></mat-divider>
        </div>
        <a
          [routerLink]="['/jobs', job.id]"
          class="flex justify-between cursor-pointer primary-color">
          @if (!job.isClosed) {
            <div
              class="self-end flex items-center w-full gap-2 hover-bold-icon hover:font-bold">
              <mat-icon class="material-symbols-rounded">edit</mat-icon>
              <span class="pt-1">Bearbeiten</span>
            </div>
          }
          @if (job.isClosed) {
            <div
              class="self-end flex items-center w-full gap-2 hover-bold-icon hover:font-bold">
              <mat-icon class="material-symbols-rounded">visibility</mat-icon>
              <span class="pt-1">Ansehen</span>
            </div>
          }
        </a>
        @if (showApplicants) {
          <div class="pt-4 pb-4">
            <mat-divider></mat-divider>
          </div>
        }
        @if (showApplicants) {
          <a
            [routerLink]="[
              '/my-schools',
              job.schoolId,
              'jobs',
              job.id,
              'applicants'
            ]"
            class="flex justify-between cursor-pointer primary-color">
            <div
              class="self-end items-center w-full flex gap-2 hover-bold-icon hover:font-bold">
              <mat-icon class="material-symbols-rounded">mail</mat-icon>
              <div class="pt-1">
                <strong class="font-bold">{{ job.countApplications }}</strong>
                <span> Bewerbung</span>
                @if (job.countApplications !== 1) {
                  <span>en</span>
                }
              </div>
            </div>
          </a>
        }
      </div>
    }
  </div>
</div>
