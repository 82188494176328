import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GeocodeResult, PlaceType2 } from '@googlemaps/google-maps-services-js';
import {
  ReplaySubject,
  Observable,
  switchMap,
  from,
  map,
  startWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  EMPTY,
  tap,
  skip,
} from 'rxjs';
import { LocationsService } from '../../services/locations.service';
import { SchoolLevels, SchoolLevelDescriptions } from '../../types/school';
import { SearchResponse, Hit } from '@algolia/client-search';
import {
  Job,
  JobFunctionsAlternative,
  JobFunctionsLeadership,
  JobFunctionsPedagogic,
  Subjects,
} from '../../types/job';
import { JobService } from '../../services/job.service';
import { getUnixTime, fromUnixTime, addMinutes } from 'date-fns';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicToolbarService } from '../../services/public-toolbar.service';
import { SchoolService } from '../../services/school.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  page = 0;
  maxPage = 0;

  readonly defaultHitsPerPage = 32;

  readonly possibleRadius = [20, 30, 50, 100];

  showFilters = false;

  fetch$ = new ReplaySubject(1);
  jobs: Hit<Job>[] = [];
  jobs$: Observable<SearchResponse<Job>> = EMPTY;
  locations$: Observable<GeocodeResult[]> = EMPTY;

  selectedLocation?: GeocodeResult;
  place?: any;

  // form controls
  searchControl = new FormControl<string>('');
  locationControl = new FormControl<string>('');
  radiusControl = new FormControl<number | undefined>(undefined);
  isFulltimeJobControl = new FormControl<boolean | null>(null);
  jobFunctionsControl = new FormControl<string[]>([]);
  levelsControl = new FormControl<(typeof SchoolLevels)[number][]>([]);
  levelDescriptionsControl = new FormControl<
    (typeof SchoolLevelDescriptions)[number][]
  >([]);
  startTimeControl = new FormControl<Date | null>(null);
  endTimeControl = new FormControl<Date | null>(null);

  // subjects
  selectedSubjects: (typeof Subjects)[] = [];
  filteredSubjects$: Observable<(typeof Subjects)[number][]> = EMPTY;
  subjectControl = new FormControl<string>('');
  reloadFilteredSubjects$ = new ReplaySubject(1);
  subjects = Subjects;

  // form field values
  jobFunctionsPedagogic = JobFunctionsPedagogic;
  jobFunctionsLeadership = JobFunctionsLeadership;
  jobFunctionsAlternative = JobFunctionsAlternative;

  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;
  minDate = new Date();

  constructor(
    private locationsService: LocationsService,
    private schoolService: SchoolService,
    private jobService: JobService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    toolbarConfig: PublicToolbarService
  ) {
    toolbarConfig.set({
      showBoth: true,
    });

    this.filteredSubjects$ = this.reloadFilteredSubjects$.pipe(
      switchMap(() =>
        from(
          this.subjectControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterSubjects(value || ''))
          )
        )
      )
    );
    this.reloadFilteredSubjects$.next(1);

    this.jobs$ = this.fetch$.pipe(
      switchMap(() =>
        from(
          this.jobService.search(this.searchControl.value || '', {
            filters: this.getDateRangeFilters(),
            facetFilters: this.getFacetFilters(),
            aroundRadius: this.getRadius(),
            aroundLatLng: this.getLatLng(),
            hitsPerPage: this.defaultHitsPerPage,
            page: this.page,
          })
        )
      )
    );

    this.jobs$.subscribe(result => {
      this.maxPage = result.nbPages - 1;
      this.jobs = result.hits;
    });

    this.locations$ = this.locationControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      filter(query => !!query && query.length > 2),
      switchMap(value => this.locationsService.searchAddress(value!)),
      filter(r => r.status === 'OK'),
      map(result => result.results)
    );

    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        skip(1),
        debounceTime(300),
        distinctUntilChanged(),
        tap(value => this.updateQueryParams('query', value!))
      )
      .subscribe(() => this.reload());

    this.radiusControl.valueChanges
      .pipe(tap(value => this.updateQueryParams('radius', value!)))
      .subscribe(() => this.reload());

    this.isFulltimeJobControl.valueChanges
      .pipe(tap(value => this.updateQueryParams('isFullTimeJob', value!)))
      .subscribe(() => this.reload());

    this.jobFunctionsControl.valueChanges
      .pipe(
        tap(value =>
          this.updateQueryParams('jobFunctions', JSON.stringify(value))
        )
      )
      .subscribe(() => this.reload());

    this.levelsControl.valueChanges
      .pipe(
        tap(value => this.updateQueryParams('levels', JSON.stringify(value)))
      )
      .subscribe(() => this.reload());

    this.levelDescriptionsControl.valueChanges
      .pipe(
        tap(value =>
          this.updateQueryParams('levelDescriptions', JSON.stringify(value))
        )
      )
      .subscribe(() => this.reload());

    this.startTimeControl.valueChanges
      .pipe(
        filter(value => !!value),
        tap(value => this.updateQueryParams('startTime', getUnixTime(value!)))
      )
      .subscribe(() => this.reload());

    this.endTimeControl.valueChanges
      .pipe(
        filter(value => !!value),
        tap(value => this.updateQueryParams('endTime', getUnixTime(value!)))
      )
      .subscribe(() => this.reload());
  }

  async ngOnInit() {
    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
    if (queryParamMap.get('location')) {
      this.selectedLocation = JSON.parse(
        queryParamMap.get('location')!
      ) as GeocodeResult;
      this.locationControl.setValue(queryParamMap.get('location'));
    }

    if (queryParamMap.get('subjects')) {
      this.selectedSubjects = JSON.parse(queryParamMap.get('subjects')!);
    }

    if (queryParamMap.get('query')) {
      this.searchControl.setValue(queryParamMap.get('query'));
    }

    if (queryParamMap.get('radius')) {
      this.radiusControl.setValue(parseInt(queryParamMap.get('radius')!));
    }

    if (queryParamMap.get('isFullTimeJob')) {
      this.isFulltimeJobControl.setValue(
        JSON.parse(queryParamMap.get('isFullTimeJob')!)
      );
    }

    if (queryParamMap.get('jobFunctions')) {
      this.jobFunctionsControl.setValue(
        JSON.parse(queryParamMap.get('jobFunctions')!)
      );
    }

    if (queryParamMap.get('levels')) {
      this.levelsControl.setValue(JSON.parse(queryParamMap.get('levels')!));
    }

    if (queryParamMap.get('levelDescriptions')) {
      this.levelDescriptionsControl.setValue(
        JSON.parse(queryParamMap.get('levelDescriptions')!)
      );
    }

    if (queryParamMap.get('startTime')) {
      const d = new Date(
        fromUnixTime(parseInt(queryParamMap.get('startTime')!))
      );
      this.startTimeControl.setValue(d);
    }

    if (queryParamMap.get('endTime')) {
      const d = new Date(fromUnixTime(parseInt(queryParamMap.get('endTime')!)));
      this.endTimeControl.setValue(d);
    }

    this.fetch$.next(1);

    const page = parseInt(queryParamMap.get('page')!) || 0;
    while (this.page !== page) {
      await this.onScroll();
    }

    if (queryParamMap.get('s')) {
      const s = queryParamMap.get('s');
      setTimeout(() => {
        const rect = document!.querySelector(`#s${s}`)?.getBoundingClientRect();
        const offset = window.innerHeight / 2;
        if (rect?.y) {
          document!.querySelector('#scroll-area')!.scrollTop =
            rect.y - offset + 100;
        }
      }, 0);
    }
  }

  reload() {
    this.page = 0;
    this.fetch$.next(1);

    setTimeout(() => {
      this.updateQueryParams('s');
    }, 0);
  }

  setLocation(geocode: GeocodeResult) {
    this.selectedLocation = geocode;
    this.updateQueryParams('location', JSON.stringify(geocode));
    this.reload();
  }

  displayLocationFn(result: GeocodeResult): string {
    return result && result.formatted_address ? result.formatted_address : '';
  }

  getRegionalFilters(props: {
    radius: number | undefined | null;
    location: GeocodeResult | undefined;
  }) {
    let filters: (string | string[])[] = [];
    if ((!props.radius || props.radius == 0) && props.location) {
      const canton = props.location.address_components.find(x =>
        x.types.includes(PlaceType2.administrative_area_level_1)
      )?.long_name;

      const city =
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.locality)
        )?.long_name ||
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.administrative_area_level_2)
        )?.long_name;

      filters = [
        canton
          ? [
              `schoolAddress.canton:${canton}`,
              `schoolAddress.canton:${canton?.replace('Kanton ', '')}`,
            ]
          : [],
      ];
      if (city) filters.push(`schoolAddress.city:${city}`);
    }

    return filters;
  }

  getFacetFilters() {
    let levels = this.levelsControl.value || [];
    levels = this.schoolService.getZyklusLevelsFilter(levels);

    const jobFunctions = this.jobFunctionsControl.value || [];
    const levelDescriptions = this.levelDescriptionsControl.value || [];
    const subjects = this.selectedSubjects || [];
    const isFullTimeJob =
      this.isFulltimeJobControl.value === null
        ? null
        : !!this.isFulltimeJobControl.value;

    const radius = this.radiusControl.value;
    const regionalFilters = this.getRegionalFilters({
      radius,
      location: this.selectedLocation,
    });

    let filters = [
      ['isPublic:true'],
      ['isClosed:false'],
      ...regionalFilters,
      subjects.map(t => `subjects:${t}`),
      jobFunctions.map(t => `function:${t}`),
      levels.map(t => `schoolLevels:${t}`),
      levelDescriptions.map(t => `schoolLevelDescriptions:${t}`),
    ] as any[];

    if (isFullTimeJob !== null) {
      filters = [...filters, `isFullTimeJob:${isFullTimeJob}`];
    }

    return filters;
  }

  getDateRangeFilters() {
    const startTime =
      this.startTimeControl.value || new Date('July 01, 2022 00:00:00');
    // add 23:59 to endtime
    const endTime = this.endTimeControl.value
      ? addMinutes(this.endTimeControl.value, 1439)
      : new Date('July 21, 2243 00:00:00');
    const startTimeUnix = getUnixTime(startTime);
    const endTimeUnix = getUnixTime(endTime);

    return `(applicationStartDateUnix:${startTimeUnix} TO ${endTimeUnix}) OR (durationFromUnix:${startTimeUnix} TO ${endTimeUnix})`;
  }

  getRadius() {
    return this.radiusControl.value || undefined;
  }

  getLatLng() {
    if (this.selectedLocation?.geometry.location) {
      const { lat, lng } = this.selectedLocation.geometry.location;
      return `${lat}, ${lng}`;
    }

    return undefined;
  }

  removeSubject(subject: any) {
    const i = this.selectedSubjects.indexOf(subject);
    this.selectedSubjects.splice(i, 1);
    this.updateQueryParams('subjects', JSON.stringify(this.selectedSubjects));
    this.reloadFilteredSubjects$.next(1);
  }

  onSelectSubject(event: MatAutocompleteSelectedEvent) {
    this.subjectControl.setValue('');
    if (!this.selectedSubjects.includes(event.option.value)) {
      this.selectedSubjects.push(event.option.value);
    }
    this.updateQueryParams('subjects', JSON.stringify(this.selectedSubjects));
    this.reloadFilteredSubjects$.next(1);
    this.reload();
  }

  private filterSubjects(value: string) {
    const filterValue = value.toLowerCase();

    return this.subjects
      .filter(sub => !this.selectedSubjects.includes(sub as any))
      .filter(sub => sub.toLowerCase().includes(filterValue));
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  async removeFilter(control: FormControl, value: any, key: string) {
    if (Array.isArray(control.value)) {
      const i = control.value.indexOf(value);
      if (i > -1) control.value.splice(i, 1);
    } else {
      control.setValue('');
    }

    if (control === this.locationControl) {
      this.selectedLocation = undefined;
    }

    await this.updateQueryParams(key);
    this.reload();
  }

  async onScroll() {
    if (this.page < this.maxPage) {
      this.page++;
      await this.updateQueryParams('page', this.page);
      this.fetch$
        .pipe(
          switchMap(() =>
            from(
              this.jobService.search(this.searchControl.value || '', {
                filters: this.getDateRangeFilters(),
                facetFilters: this.getFacetFilters(),
                aroundRadius: this.getRadius(),
                aroundLatLng: this.getLatLng(),
                hitsPerPage: this.defaultHitsPerPage,
                page: this.page,
              })
            ).pipe(
              map(r => r.hits),
              filter(r => r.length > 0)
            )
          )
        )
        .subscribe(results => {
          this.jobs = [...this.jobs, ...results];
        });
    }
  }

  async navigate(event: Event, job: Hit<Job>, index: number) {
    event.preventDefault();
    await this.updateQueryParams('s', index);
    await this.router.navigate(['/jobs', job.objectID], {
      queryParamsHandling: 'merge',
    });
  }

  private async updateQueryParams(
    key: string,
    value?: string | number | boolean
  ) {
    const queryParams = {} as any;
    queryParams[key] = value;
    queryParams['page'] = this.page;
    return this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams,
    });
  }
}
